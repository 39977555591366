$bestearpluglawyers-green: #52793a;
$bestearpluglawyers-hover: #fcffea;
$bestearpluglawyers-orange: #f38620;
$bestearpluglawyers-grey: #565656;
$bestearpluglawyers-grey-light: #f1f1f1;

:export {
    green: $bestearpluglawyers-green;
    hover: $bestearpluglawyers-hover;
    orange: $bestearpluglawyers-orange;
    grey: $bestearpluglawyers-grey;
    greyLight: $bestearpluglawyers-grey-light;
}
