
            @import 'src/styles/mixins.scss';
        
@import "@styles/sassVariables";

.wrapper {
    padding: 50px 0;
    background-color: $bestearpluglawyers-green;
    @apply mx-auto px-5 flex justify-between items-center;
    color: #fff;

    h2 {
        @apply text-lg mb-4;
        font-style: normal;
        font-weight: 700;
        line-height: 38px;
        letter-spacing: 0em;
        @include md {
            @apply text-2xl;
        }
    }
    p {
        font-weight: 300;
        letter-spacing: 0em;
        font-size: 15px;
        line-height: 32px;
        @include md {
            font-size: 20px;
        }
    }
}
